
body{
  font-family: 'Assistant', sans-serif;
/* font-family: 'Josefin Sans', sans-serif;
font-family: 'Libre Baskerville', serif; */
}
*{
  font-family: 'Assistant', sans-serif;
/* font-family: 'Josefin Sans', sans-serif;
font-family: 'Libre Baskerville', serif; */
font-weight: 700;
font-size: medium;
}
.naving{
  background-color: #FFF;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 10px 5px rgba(0,0,0,0.3);
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  

  .naving-btn{
    background-color: #57b7c0;
    font-weight: 700;
  }
  .header2-info h1{
 font-size: 58px;
 line-height: 1.2;
 /* font-family: 'Libre Baskerville', serif; */
 font-weight: 700;
 color: #fff;
 /* font-family: 'Libre Baskerville', serif; */
 font-family: 'Assistant', sans-serif;
 

  }
  .header2-info p{
 color: #fff;
 font-family: 'Libre Baskerville', serif;
  
}
  .header2{
    background:rgba(31, 185, 231, 0.5) url("./Img/header2-bg.jpg");
    background-size: contain;
    background-blend-mode: darken;
    /* position: relative; */
    background-repeat: no-repeat;
    background-size: cover;
  }
  .form{
    background-color: #57b7c0;
    padding: 25px  20px;
    font-family: 'Libre Baskerville', serif;

  }
  .naving-btn1{
  background-color: #fff;  
  font-size: 700;
  font-weight: 700;
}
.naving-btn1:hover{
  background-color: #749BC2;
  color: white;
}
.curve{
    border-top: 4px solid #57b7c0 ;
    border-radius: 50%;
    height: 200px;
    width: 200px;
}

/* .whychoose */
.home-icon{
    font-size: 60px;
    /* background-color: #57b7c0;
    color: #fff;
    border-radius: 5px; */
}
.whychoose-item > h4{
    color: #57b7c0;
}

.whychoose-item  h4{
  font-weight: 700;
}
/* .whychoose > h1{

} */
.whychoose h1{
  font-weight: 600;
}
.naving-btn2{
    background-color: #57b7c0;
    color: #fff;
    font-weight: 700;
}

/* project */

.project-info{
   background-color: #749BC2;
   /* margin-left: 40px; */
   padding-left: 40px;
   /* margin-right: 40px; */
}
.project-info > p {
   margin-right: 40px;
   
  
}
.project-info > h3{
    font-size: 40px;
}
.project-info > p{
 color: #F6F4EB;
}

.howitworks{
  height: 80%;
}
.howitworks h1{
  font-weight: 600;
}
/* brands */

/* .brand-scroller-container {
  width: 100%;
  overflow: hidden;
  position: relative;
} */

/* .brand-scroller {
  display: flex;
  animation: scroll 30s linear infinite;
} */

.brand-list {
  width:auto;
  height: 90px;
}



.our-brands{
  background-color: #57b7c0;
  height: 300px;
}
.our-brands h1{
  font-weight: 700;
}
.brand-card{
  height: 100%;
  width: 100%;
  
}
.brand-card  img{
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* border: 10px solid #57b7c0; */
}
.brandsicon{
  background-color: #57b7c0;
  padding: 10px 10px;
  margin: 50px 0px;
}
.circle{
  /* border-radius: 50% !important; */
  height: 100px;
  width: 250px;
}
.text-justify{
  text-align:justify;
  line-height: 1.7;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


/* news */
.news h1{
  font-weight: 600;
}
.news-img{
  height: 350px;
}
/* review */
.card-review{
  border-radius: 5px;
}
.review h2 {
  font-weight: 700;
}
/* freeconsult */
.freeconsult{
  background-color: #57b7c0;
}
.naving-btn3{
  background-color: #749BC2;
  color:white;
  font-weight: 700;
}
.naving-btn3:hover{
  background-color: black;
  color: white;
}
.freeconsult h1 {
  font-weight: 700;
}
/* about */
.myswiper2{
  height: 490px;
}
.team-img{
  width: 100%;
}

.card1 {
  position: relative;
  margin: 20px 0;
  height: 400px;
  background: #fff;
  transform-style: preserve-3d;
  transform: perspective(2000px);
  transition: 1s;
  box-shadow: inset 100px 0 50px rgba(0, 0, 0, 0.5);
}

.card1:hover {
  z-index: 1111;
  transform: perspective(2000px) rotate(-10deg);
  box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5);
}

.card1 .img-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  box-sizing: border-box;
  transform-origin: left;
  z-index: 1;
  transition: 1s;
}

.card1 .img-container img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card1:hover .img-container {
  transform: rotateY(-135deg);
}

.card1 .card-details {
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 20px;
  color: #000;
}

.card1 .card-details h2 {
  margin: 0;
  padding: 0.5em 0;
  text-transform: uppercase;
  font-size: 2em;
  color: #ed363a;
}

.card1 .card-details p {
  margin: 0;
  padding: 0;
  line-height: 25px;
  font-size: 1.1em;
}

.about-cards{
  height: auto;
}

/* ourProject page */

.Ourproject{
  background-image: url("./Img/service-bg.jpg");
  background-size:cover;
  height: 450px;
  position: relative;

}
.Ourproject-img{
  width: 80%;
  height: 90% ;
  padding-left: 90px;
  padding-top: 110px;

}
.project-head{
  position: absolute;
    right: 55px;
    bottom: 37px;
    /* right: 0;
    bottom: 0; */
    background-color: #57b7c0;
    padding:22px 55px;
    font-family: 'Assistant', sans-serif;
    /* font-family: 'Libre Baskerville', serif; */
    font-weight: 700;
    font-size: 55px;
    border-radius: 10px;
}
.project-head1{
  position: absolute;
    right: 55px;
    bottom: 50px;
}
.card-image{
  width: 100%;
  height: 100%;
}

.flip-card {
  background-color: transparent;
  /* width: 300px; */
  height: 350px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #57b7c0;
  color: white;
  transform: rotateY(180deg);
}

/* blogs */
.blogs{
  background-image: url("./Img/blogImg.jpg");
}
/* footer */
.footer-list > li{
  list-style: none
  
} 

.footer{
  color: white;
  background-color: #61677A;
}
.footer-list a{
  text-decoration: none;
  color: white;
}

/* how it works page  */
.howitworks1{
  background-image: url("./Img/howwork-bg.jpg");
  

}
.howWorks{
  height: 2100px;
}
.howWorks h3{
  background-color: #57b7c0;
  border-radius: 50%;
  width: 60px;
  font-size: 45px;
  text-align: center;
  font-weight: 300;
}
.howWorks h4{
 font-weight: 600;
}
.card-image1{
  width: 100%;
  height: 80%;
  /* margin-top: 280px; */
}

/* contact page  */

.contact{
  background-image: url("./Img/contact1.jpg");
}
.contact-info li{
  list-style: none;
  line-height: 2.5;
}
.contact-info h1{
  font-weight: 700;
}
.contact-info h3{
  font-weight: 700;


}
/* careers page */
.Careers{
  background-image: url("./Img/careers1.jpg");

}
.career-card{
  height: 237px;
}
.careers-text{
  position: relative;
  background-blend-mode: darken;
  height: 300px;
  background:rgba(2, 6, 7, 0.5) url("./Img/careers2.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.careers-text h1{
  font-weight: 700;
  font-size: 50px;
}
.careers-text h6{
  font-weight: 700;

}

/* testimonial */


/* ReferFriend */
.ReferFriend{
  background-image: url("./Img/Referfriend1.jpg");
  

}
.Referfriend-text{
  height: 360px;
  position: relative;
  background-blend-mode: darken;
  background:rgba(2, 6, 7, 0.6) url("./Img/header5.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.form-refer{
  /* height: 450px; */
  width: 88%;
}
.form-refer h2{
  font-weight: 700;
}
.footer-list a {
   color: white;
}
.footer-list a :hover{
  color: #57b7c0;
}

/* Services Home Page  */
/* home  */
.Homevilla{
  background-image: url("./Img/Homepageimg.jpg");
  background-size: cover;
  background-size:100% 100%;
  background-repeat: no-repeat;
  height: 450px;
  position: relative;
  
  /* background-position: 30% 30%; */
}
.Vilaa h3{
  font-weight: 600;
}
.card-heading{
  border-top: 6px solid #57b7c0;
  font-weight: 600;
}

/* Rooms */
.Rooms{
  background-image: url("./Img/RoompageImg.jpg");
  background-size: cover;
  background-size:100% 100%;
  background-repeat: no-repeat;
  height: 450px;
  position: relative;
}

/* Kitchen */
.Kitchen{
  background-image: url("./Img/kitchencontent-btg.jpg");
}
/* Livingroom */
.Livingroom{
  background-image: url("./Img/Livingcontent-bg.jpg");
}
/* Poojaroom */
.Poojaroom{
  background-image: url("./Img/poojaContent-bg.jpg");

}

.about h6{
  line-height: 32px;
}

.about h2{
  font-weight: 600;
}
.card-items{
  height:460px;
}

.FAQs{
  background-image: url("./Img/Livingcontent-bg.jpg");
}
@media only screen and (max-width: 600px) {
  .header2-info h1{
 font-size: 30px;  
}
.whychoose-item{
    margin: 0 auto;
}
.project-info{
    padding-left: 0px;
    text-align: center;

}

/* .project{
    display: none;
} */

.brand-card{
  height: 100px;
}
.Ourproject-img{
  padding: 0;
  /* height: 40%; */
  padding-top: 100px;
  /* height: 150px; */
  margin-left: 28px;
}
.project-head{
  font-size: 30px;
    margin: 48  px;

}
.card-image1{
  /* margin-top: 60px; */
  height: 300px;
}
.howWorks{
  height: 2590px;;
}
/* .howWorks h4{
  margin-bottom: 190px;
} */

.howitworks1{
  height: 400px;
}
.project-head1{
  margin: 10px;
  font-size: 32px;
}

.career-card{
  height: 275px;
}
.form-refer{
  width: 100%;
}

.blogs{
  height: 418px;
}
.blog-head{
  font-size: 40px;
  margin: 37px;
}
.howitwork-head{
  font-size: 27px;
    margin: 8px;
}
.careers-text{
  height: 380px;
}
.Careers{
  height: 400px;
}
.career-head {
  font-size: 44px;
  margin: 11px;
}
.ReferFriend{
  height: 380px;
}
.referfriend-head{
  font-size: 26px;
  margin: 5px;
}
.referfriend-info{
  height: 1500px;
}
.whychoose{
  height: 2123px;
}
.Ourproject{
  height: 510px;
}
.service-head{
  font-size: 28px;
  margin-left: 12%;
}
/* .project-head{
  margin-left: 20px ;
} */
.project-info > p {
 margin-left: 45px;
}
.content-img{
  width: 100%;
}


.room-head{
  font-size: 40px;
  margin: 25px;
}
.Kitchen-head{
  font-size: 36px;
  margin: 15px;
}
.living-head {
  font-size: 30px;
  margin: 0px;
}
.pooja-head{
  font-size: 32px;
  margin: 0px;
}
.FAQ-head{
  font-size: 35px;
}
.Testimonial-head{
  margin: 0px;
    font-size: 33px;
}
.villa-head{
  font-size: 18px;
  margin-left: 23%;
}
 }

 /* .leavenav{
  padding-top: 70px;
  background-color: #FFF6E0;
 } */

 .nav-button{
  color: white;
 }
 .nav-button:hover{
  color: black;
 }
@media only screen and (min-width: 998px) {
 .swiper-slide .projectimg {
  height: 749px;
 }
 .Ourproject{
  /* background-image: url("./Img/Ourproject1.jpg"); */
  background-size: cover;
  height: 450px;
  position: relative;

}
}
@media only screen and (min-width: 1400px) {
  .Ourproject{
    background-size: cover;
    height: 520px;
    position: relative;
  
  }
  .swiper-slide img {
    height: 694px;
   }
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color:#57b7c0 !important;
}

.footer-hyperlnk{
  text-decoration: none;
  color: black;
}
.footer-hyperlnk:hover{
  color:#57b7c0;
}

.footer-hyperlnk1{
  text-decoration: none;
  color: white;
}
.footer-hyperlnk1:hover{
  color:#57b7c0;
}